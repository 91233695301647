<template>
  <div class="col-12"><br>
    <div class="card">
        <div class="card-header">
            <h3 id='ffff'>
                تقرير تفعيل المنصة الاسبوعي للمعلمين 
                <u id="fromto"></u>
            </h3>

                <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="📥 طباعة / تصدير"
                variant="primary"
                >
                <b-dropdown-item @click="printDiv()">
                    <i class="fa fa-print"></i>
                    طباعة التقرير
                </b-dropdown-item><hr>
                <b-dropdown-item @click="exportToExcel()">
                    <i class="fa fa-download"></i>
                    تصدير التقرير اكسل
                </b-dropdown-item>
                </b-dropdown>
        </div>
        <div class="card-body" id="headerTable">
                <header  class='dnone'>
                    <div style='width: 100%; padding: 20px;'>
                        <div style='width: 30%; text-align: center; display: inline-block; position: relative;top:-20px !important'>
                            <h4>
                                <img src="https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg"
                                    style='width: 40%' alt="">
                                <br>
                                وزارة التعليم
                                <br>
                                <div id="school_name">{{school_name}}</div>
                                </h4>
                        </div>
                        <div style='width: 30%; text-align: center; display: inline-block; position: relative; top: 5px'>
                            <br><br><br><br>
                            <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 80%'>
                        </div>
                        <div id='ccc__cc'
                            style='width: 30%; text-align: center; display: inline-block; position: relative; top: -20px !important; text-align: right'>
                            <h3>
                                <div id="date2"></div>
                            </h3>
                        </div>
                    </div>
                </header>
            <table  class="table table-stipred table-sm">
                
                <thead style="background: #eee">
                    <th>
                        م
                    </th>
                    <th>
                        المعلم
                    </th>
                    <th>
                        اجمالي الحصص<br> في الجدول
                    </th>
                    <th>
                        عدد الدروس التي <br>تحتوي على اثراء
                    </th>
                    <th>
                        عدد الدروس التي<br> تحتوي على واجبات
                    </th>
                    <th>
                        عدد الدروس التي<br> تحتوي على اختبار
                    </th>
                    <th>
                        عدد الدروس التي <br>
                        تحتوي على نشاط
                    </th>
                    <th>
                        اجمالي الدروس المعدة
                    </th>
                    <th>
                        اجمالي الدروس<br> الغير معدة
                    </th>
                    <th>
                        نسبة التفعيل
                    </th>
                    <th>
                    </th>
                </thead>
                <tbody></tbody>
            </table>
            <footer class='dnone'>مدير المدرسة: {{ admin_name }}</footer>
        </div>
        <textarea class='get-local-storage' name="teachers" style='display: none'></textarea>
    </div>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            arr: [],
            teachers: [],
            school_name: decodeURI(window.location.href.split("school_name=")[1].split("&")[0]),
            admin_name: decodeURI(window.location.href.split("admin_name=")[1].split("&")[0]),
        }
    },
    created(){
        var g = this;
        var v = setInterval(() => {
            if($(".get-local-storage-value[name='teachers']").length){
                if($(".get-local-storage[name='teachers-week']").length == 0){
                    $("body").append(`<textarea class='get-local-storage' name="teachers-week" style='display: none'></textarea>`)
                }
                if($(".get-local-storage-value[name='teachers-week']").length){
                clearInterval(v) 
                }else{
                    return
                }
            }else{
                return;
            }
           $("#fromto").html(decodeURIComponent(window.location.href.split("date=")[1].split("&s")[0]))
            g.teachers = JSON.parse($(".get-local-storage-value[name='teachers']").val())
            g.arr = JSON.parse($(".get-local-storage-value[name='teachers-week']").val())
            var r = {
                teachers: g.teachers,
                arr: g.arr
            }
            var i = 0;
            var t_ethrat = 0;
            var t_homeworks = 0;
            var t_exams = 0;
            var t_actives = 0;
            var t_notcreated = 0;
            var t_t = 0;
            var t_total = 0;
            r.teachers.forEach(teacher => {
                if (r.arr[teacher.id]) {
                    i++;
                    var ethrat = 0;
                    var homeworks = 0;
                    var exams = 0;
                    var actives = 0;
                    var notcreated = 0;
                    var total = 0
                    t_t = t_t + r.arr[teacher.id]?.total_lessons
                    for (const [key, value] of Object.entries(r.arr[teacher.id]?.lessons)) {
                        if (value.ethrat > 0) {
                            ethrat = ethrat + 1
                            t_ethrat = t_ethrat + 1
                        }
                        if (value.homeworks > 0) {
                            homeworks = homeworks + 1
                            t_homeworks = t_homeworks + 1
                        }
                        if (value.exams > 0) {
                            exams = exams + 1
                            t_exams = t_exams + 1
                        }
                        if (value.actives > 0) {
                            actives = actives + 1
                            t_actives = t_actives + 1
                        }
                        if (value.notcreated) {
                            notcreated = notcreated + 1;
                            t_notcreated = t_notcreated + 1;
                        } else {
                            total = total + 1
                            t_total = t_total + 1
                        }
                    }
                    $("tbody").append(`
                <tr>
                    <td>
                        ${i}
                    </td>
                    <td>
                        ${teacher.name}
                    </td>
                    <td>
                        ${r.arr[teacher.id]?.total_lessons}
                    </td>
                    <td>
                        ${ethrat}
                    </td>
                    <td>
                        ${homeworks}
                    </td>
                    <td>
                        ${exams}
                    </td>
                    <td>
                        ${actives}
                    </td>
                    <td>
                        ${total}
                    </td>
                    <td>
                        ${notcreated}
                    </td>
                    <td>
                        ${isNaN((total / (total + notcreated) * 100).toFixed(0)) ? '0' : (total / (total + notcreated) * 100).toFixed(0)}%
                    </td>
                    <td class='hideme'>
                        <button class='details' id='${teacher.id}' name='${teacher.name}'>التفاصيل</button>
                    </td>
                </tr>
                `)
                }
            });

            $("td:contains('%')").css("background", '#E8D2A6')
            $("td:contains('0%')").each(function () {
                if (new Number($(this).text().trim().replace('%', '')) == 0) {
                    $(this).css("background", '#F48484')
                }
            })
            $("td:contains('100%')").css("background", '#CDE990')

            function countItem(item, arr) {
                var r = 0
                arr.forEach(function (a) {
                    if (a[item]) {
                        r = r + (a[item] === true ? 1 : a[item]);
                    }
                })
                return r;
            }

            $(".details").click(function () {
                $("#myModal").fadeIn('slow')
                var daysNames = {
                    0: "Sunday",
                    1: "Monday",
                    2: "Tuesday",
                    3: "Wednesday",
                    4: "Thursday",
                }
                var days = {
                    Sunday: [],
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: []
                }
                for (const [key, value] of Object.entries(r.arr[$(this).attr('id')].days)) {
                    value.DayNumber = daysNames[value.DayNumber]
                    r.arr[$(this).attr('id')].lessons[value.Data].date = new Number(value.Date.replace("Date(", "").replace(')', '').replace('/', '').replace('/', ''))
                    days[value.DayNumber].push(r.arr[$(this).attr('id')].lessons[value.Data])
                }
                $("#modal-content").html(`
                <h4>${$(this).attr('name')}</h4>
                <p>${$('#ccc__cc').text()}</p>
                <table>
                    <thead>
                        <th>#</th>
                        <th>الاحد</th>
                        <th>الاثنين</th>
                        <th>الثلاثاء</th>
                        <th>الاربعاء</th>
                        <th>الخميس</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>التاريخ</td>
                            <td>${days['Sunday'].length > 0 ? new Date(days['Sunday'][0].date).toISOString().split("T")[0] : '--'}</td>
                            <td>${days['Monday'].length > 0 ? new Date(days['Monday'][0].date).toISOString().split("T")[0] : '--'}</td>
                            <td>${days['Tuesday'].length > 0 ? new Date(days['Tuesday'][0].date).toISOString().split("T")[0] : '--'}</td>
                            <td>${days['Wednesday'].length > 0 ? new Date(days['Wednesday'][0].date).toISOString().split("T")[0] : '--'}</td>
                            <td>${days['Thursday'].length > 0 ? new Date(days['Thursday'][0].date).toISOString().split("T")[0] : '--'}</td>
                        </tr>
                        <tr>
                            <td>الحصص في الجدول</td>
                            <td>${days['Sunday'].length}</td>
                            <td>${days['Monday'].length}</td>
                            <td>${days['Tuesday'].length}</td>
                            <td>${days['Wednesday'].length}</td>
                            <td>${days['Thursday'].length}</td>
                        </tr>
                        <tr>
                            <td> الاثراءات</td>
                            <td>${countItem('ethrat', days['Sunday'])}</td>
                            <td>${countItem('ethrat', days['Monday'])}</td>
                            <td>${countItem('ethrat', days['Tuesday'])}</td>
                            <td>${countItem('ethrat', days['Wednesday'])}</td>
                            <td>${countItem('ethrat', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td>الواجبات</td>
                            <td>${countItem('homeworks', days['Sunday'])}</td>
                            <td>${countItem('homeworks', days['Monday'])}</td>
                            <td>${countItem('homeworks', days['Tuesday'])}</td>
                            <td>${countItem('homeworks', days['Wednesday'])}</td>
                            <td>${countItem('homeworks', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td>الاختبارات</td>
                            <td>${countItem('exams', days['Sunday'])}</td>
                            <td>${countItem('exams', days['Monday'])}</td>
                            <td>${countItem('exams', days['Tuesday'])}</td>
                            <td>${countItem('exams', days['Wednesday'])}</td>
                            <td>${countItem('exams', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td>النشاطات</td>
                            <td>${countItem('actives', days['Sunday'])}</td>
                            <td>${countItem('actives', days['Monday'])}</td>
                            <td>${countItem('actives', days['Tuesday'])}</td>
                            <td>${countItem('actives', days['Wednesday'])}</td>
                            <td>${countItem('actives', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td>الدروس المعدة</td>
                            <td>${days['Sunday'].length - countItem('notcreated', days['Sunday'])}</td>
                            <td>${days['Monday'].length - countItem('notcreated', days['Monday'])}</td>
                            <td>${days['Tuesday'].length - countItem('notcreated', days['Tuesday'])}</td>
                            <td>${days['Wednesday'].length - countItem('notcreated', days['Wednesday'])}</td>
                            <td>${days['Thursday'].length - countItem('notcreated', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td> الدروس
                            الغير معدة</td>
                            <td>${countItem('notcreated', days['Sunday'])}</td>
                            <td>${countItem('notcreated', days['Monday'])}</td>
                            <td>${countItem('notcreated', days['Tuesday'])}</td>
                            <td>${countItem('notcreated', days['Wednesday'])}</td>
                            <td>${countItem('notcreated', days['Thursday'])}</td>
                        </tr>
                        <tr>
                            <td>نسبة التفعيل</td>
                            <td>
                                ${(((days['Sunday'].length - countItem('notcreated', days['Sunday'])) / days['Sunday'].length) * 100).toFixed(0)}%
                            </td>
                            <td>
                                ${(((days['Monday'].length - countItem('notcreated', days['Monday'])) / days['Monday'].length) * 100).toFixed(0)}%
                            </td>
                            <td>
                                ${(((days['Tuesday'].length - countItem('notcreated', days['Tuesday'])) / days['Tuesday'].length) * 100).toFixed(0)}%
                            </td>
                            <td>
                                ${(((days['Wednesday'].length - countItem('notcreated', days['Wednesday'])) / days['Wednesday'].length) * 100).toFixed(0)}%
                            </td>
                            <td>
                                ${(((days['Thursday'].length - countItem('notcreated', days['Thursday'])) / days['Thursday'].length) * 100).toFixed(0)}%
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style='text-align:center;width:100%'>
                <br><button class='printcontent' style='width: 300px'>طباعة هذه التفاصيل</button></div>
                `)
                $("#modal-content").html($("#modal-content").html().replaceAll('NaN%', '<i>غير مقرر</i>'))
                $("#modal-content td:contains('%')").css("background", '#E8D2A6')
                $("#modal-content td:contains('0%')").each(function () {
                    if (new Number($(this).text().trim().replace('%', '')) == 0) {
                        $(this).css("background", '#F48484')
                    }
                })
                $("#modal-content td:contains('100%')").css("background", '#CDE990')
                $('.printcontent').click(function () {
                    var mywindow = window.open('', 'naqar.net');
                    mywindow.document.write('<html><head><title>' + document.title + '</title>');
                    mywindow.document.write(`</head><body >
                    <style>
                    table, td, th {
                        border: 1px solid;
                    }
                    *{
                        direction:rtl
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .printcontent{
                        display:none
                    }
                    </style>
                    `);
                    mywindow.document.write(document.getElementById('modal-content').innerHTML);
                    mywindow.document.write('</body></html>');
                    mywindow.document.close(); // necessary for IE >= 10
                    mywindow.focus(); // necessary for IE >= 10*/
                    mywindow.print();
                    mywindow.close()
                })
            })

            $("tbody").append(`
            <tr style='background:#ddd;'>
                <td style='border: none !important'>
                </td>
                <td>
                <strong>الأجمالي</strong>
                </td>
                <td>
                    ${t_t}
                </td>
                <td>
                    ${t_ethrat}
                </td>
                <td>
                    ${t_homeworks}
                </td>
                <td>
                    ${t_exams}
                </td>
                <td>
                    ${t_actives}
                </td>
                <td>
                    ${t_total}
                </td>
                <td>
                    ${t_notcreated}
                </td>
                <td>
                    ${(t_total / (t_total + t_notcreated) * 100).toFixed(0)}%
                </td>
                <td class='hideme'></td>
            </tr>
            `)
        $("body").append(`
        <!-- The Modal -->
        <div id="myModal" class="modal">

        <!-- Modal content -->
        <div class="modal-content">
            <u><strong style='color: darkred; cursor: pointer' onclick="location.reload()">&times; اغلاق هذه النافذة</strong></u>
            <br><br>
            <div id='modal-content'></div>
        </div>

        </div>
        <style>
        body {font-family: Arial, Helvetica, sans-serif;}
        #modal-content table *{
            font-size: 20px;
            padding-right: 10px !important;
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        /* The Modal (background) */
        .modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        }

        /* Modal Content */
        .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 60%;
        }

        /* The Close Button */
        .close {
        color: #aaaaaa;
        float: right;
        font-weight: bold;
        }

        .close:hover,
        .close:focus {
        color: #000;
        cursor: pointer;
        }
        </style>
        `)
        }, 100);
    },
    methods: {

        printDiv() {
            document.getElementById("date2").innerHTML = document.getElementById("ffff").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
            #headerTable{
                  position: relative;
            top: -100px;
            width: 90%;
            }
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                text-align: center
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    display:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>';
                excelTemplate = excelTemplate.replaceAll("<img", "<br style='display:none'")
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
            setTimeout(() => {
                window.location = window.location.href
            }, 1000);
        },
    }
}
</script>

<style>
#date2{
    display: none;
}
th,td{
    text-align: center;
}
</style>